<template>
  <emoji-picker ref="emojiPicker" class="emoji" @emoji="insert" :search="search">
    <div class="emoji__invoker" :class="{ focus : isEmojiPickerDisplayed }" slot="emoji-invoker" slot-scope="{ events: { click: clickEvent } }" @click.stop="clickEvent">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M18.443 9.333a7.523 7.523 0 01-.706 4.76c-.753 1.482-1.975 2.712-3.501 3.524a9.079 9.079 0 01-5.033 1.016 8.899 8.899 0 01-4.748-1.859c-1.341-1.059-2.301-2.477-2.75-4.062a7.496 7.496 0 01.24-4.8c.604-1.54 1.702-2.869 3.142-3.807A9.002 9.002 0 0110 2.667V1.333c-1.978 0-3.911.548-5.556 1.573C2.8 3.932 1.518 5.39.761 7.095a8.757 8.757 0 00-.569 5.393c.386 1.81 1.338 3.473 2.737 4.778 1.398 1.306 3.18 2.195 5.12 2.555 1.94.36 3.95.175 5.778-.532 1.827-.706 3.389-1.902 4.488-3.437A8.897 8.897 0 0020 10.667a9.157 9.157 0 00-.114-1.334h-1.443z" fill="#7B7979"/><path d="M20 2.667h-2.857V0h-1.429v2.667h-2.857V4h2.857v2.667h1.429V4H20V2.667zM6.786 7.333c-.353 0-.699.098-.992.281a1.697 1.697 0 00-.658.748c-.135.305-.17.64-.102.963.07.323.24.62.489.854.25.233.568.391.914.456.347.064.706.03 1.032-.095.326-.126.605-.34.801-.614a1.589 1.589 0 00.17-1.566 1.65 1.65 0 00-.387-.542 1.785 1.785 0 00-.582-.361 1.883 1.883 0 00-.685-.124zm6.428 0c-.353 0-.698.098-.992.281a1.697 1.697 0 00-.657.748c-.136.305-.171.64-.102.963.069.323.239.62.489.854.25.233.568.391.914.456.346.064.705.03 1.032-.095.326-.126.605-.34.801-.614a1.589 1.589 0 00.169-1.566 1.65 1.65 0 00-.387-.542 1.786 1.786 0 00-.581-.361 1.883 1.883 0 00-.686-.124zm-8.214 6a5.637 5.637 0 002.121 1.951 6.13 6.13 0 002.879.713 6.13 6.13 0 002.879-.713A5.638 5.638 0 0015 13.334H5z" fill="#7B7979"/></svg>
    </div>
    <div slot="emoji-picker" slot-scope="{ emojis, insert }">
      <div class="emoji__picker">
        <div class="emoji__search">
          <input type="text" v-model="search" placeholder="Search">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6.842 13.684a6.801 6.801 0 004.189-1.444L14.79 16 16 14.79l-3.76-3.759a6.8 6.8 0 001.445-4.189A6.85 6.85 0 006.842 0 6.85 6.85 0 000 6.842a6.85 6.85 0 006.842 6.842zm0-11.973a5.137 5.137 0 015.132 5.131 5.137 5.137 0 01-5.132 5.131 5.137 5.137 0 01-5.131-5.131 5.137 5.137 0 015.131-5.131z" fill="#7B7979"/></svg>
        </div>
        <div class="emoji__group">
          <div v-for="(emojiGroup, category) in emojis" :key="category">
            <h5>{{ category }}</h5>
            <div class="emojis">
              <span
                v-for="(emoji, emojiName) in emojiGroup"
                :key="emojiName"
                @click="insert(emoji)"
                :title="emojiName"
              >{{ emoji }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </emoji-picker>
</template>

<script>
import { EmojiPicker } from "vue-emoji-picker"

export default {
  data () {
    return {
      search: "",
      emojiDisplayInterval: null,
      isEmojiPickerDisplayed: false
    }
  },
  components: {
    EmojiPicker
  },
  methods: {
    insert (emoji) {
      this.$emit("insert-emoji", emoji)
    },
    checkIfEmojiDisplay () {
      this.emojiDisplayInterval = setInterval(() => {
        if (this.$refs.emojiPicker) {
          this.isEmojiPickerDisplayed = this.$refs.emojiPicker.display.visible
        } else this.isEmojiPickerDisplayed = false
        this.$emit("emoji-display-value", this.isEmojiPickerDisplayed)
      }, 50)
    },
    clearEmojiCheckInterval () {
      clearInterval(this.emojiDisplayInterval)
    }
  },
  beforeDestroy () {
    this.clearEmojiCheckInterval()
  }
}
</script>

<style lang="scss">
.emoji {
  &__invoker {
    cursor: pointer;
    padding: 3px;
    border: 1px solid #F2F2F2;
    border-radius: 4px;
    svg {
      width: 16px;
      height: 12px;
    }
    &.focus,
    &:hover {
      background: #2A2D52;
      svg {
        path {
          fill: white;
        }
      }
    }
  }
  &__search {
    height: 60px;
    padding: 10px;
    display: flex;
    align-items: center;
    position: relative;
    input {
      width: 100%;
      padding: 5px 10px !important;
      outline: none;
      background: #EFEFEF;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
    }
    svg {
      position: absolute;
      right: 20px;
    }
  }
  &__group {
    padding: 5px 5px 10px 10px;
    max-height: 200px;
    overflow: auto;
    h5 {
      margin-bottom: 0;
      color: #b1b1b1;
      text-transform: uppercase;
      font-size: 16px;
      cursor: default;
    }
    .emojis {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &:after {
        content: "";
        flex: auto;
      }
      span {
        padding: 3px;
        margin-right: 5px;
        cursor: pointer;
        border-radius: 5px;
        &:hover {
          background: white;
          cursor: pointer;
        }
      }
    }
  }
  &__picker {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    width: 300px;
    background-color: #EFEFEF;
    position: absolute;
    right: 0;
    z-index: 2;
    border-radius: 12px;

  }
}
</style>
