import {
  Mark,
  mergeAttributes
} from "@tiptap/core"
import colors from "@/assets/scss/main.scss"

export const CustomHighlight = Mark.create({
  name: "highlight",

  addOptions () {
    return {
      multicolor: false,
      HTMLAttributes: {}
    }
  },

  addAttributes () {
    if (!this.options.multicolor) {
      return {}
    }

    return {
      color: {
        default: null,
        parseHTML: parseHtml,
        renderHTML: renderHtml
      },
      id: {
        default: null,
        parseHTML: element => element.getAttribute("data-id") || null
      }
    }
  },

  parseHTML () {
    return [
      {
        tag: "span"
      }
    ]
  },

  renderHTML ({ HTMLAttributes }) {
    return ["span", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  }
})

export const renderHtml = attributes => {
  if (!attributes.color || !attributes.id) {
    return {}
  }

  return {
    "data-id": attributes.id,
    "data-color": attributes.color,
    style: `background-color: ${attributes.color}; color: ${colors.orange}; padding: 0.125em; border-radius: 0.25em;`
  }
}

export const parseHtml = element => element.getAttribute("data-color") || element.style.backgroundColor
