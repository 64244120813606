import Mention from "@tiptap/extension-mention"
import { PluginKey } from "@tiptap/pm/state"
import colors from "@/assets/scss/main.scss"

export const MentionPluginKey = new PluginKey("mention")

export const CustomMention = Mention.extend({
  addOptions () {
    return {
      HTMLAttributes: {
        style: `background-color: ${colors.orange12}; color: ${colors.orange}; padding: 0.125em; border-radius: 0.25em;`
      },
      renderText ({ options, node }) {
        return `${options.suggestion.char}${node.attrs.label ? node.attrs.label : node.attrs.id}`
      },
      renderHTML ({ options, node }) {
        const label = `${options.suggestion.char}${node.attrs.label ? node.attrs.label : node.attrs.id}${options.suggestion.closeChar}`
        return [
          "span",
          { ...this.HTMLAttributes, "data-id": node.attrs.id },
          label
        ]
      },
      suggestion: {
        char: "{{",
        closeChar: "}}",
        pluginKey: MentionPluginKey,
        command: ({ editor, range, props }) => {
          // increase range.to by one when the next node is of type "text"
          // and starts with a space character
          const nodeAfter = editor.view.state.selection.$to.nodeAfter
          const overrideSpace = nodeAfter && nodeAfter.text ? nodeAfter.text.startsWith(" ") : false

          if (overrideSpace) {
            range.to += 1
          }

          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              {
                type: this.name,
                attrs: props
              },
              {
                type: "text",
                text: " "
              }
            ])
            .run()

          if (window.getSelection()) {
            window.getSelection().collapseToEnd()
          }
        },
        allow: ({ state, range }) => {
          const $from = state.doc.resolve(range.from)
          const type = state.schema.nodes[this.name]
          const allow = !!$from.parent.type.contentMatch.matchType(type)

          return allow
        }
      }
    }
  }
})
