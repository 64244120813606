<template>
  <div>
    <div
      v-if="editor"
      id="rich-text-editor-202408150831"
      class="editor"
    >
      <div
        :class="getEditorClass"
      >
        <bubble-menu
          v-show="dynamicDataActive"
          :editor="editor"
          :should-show="() => true"
          :tippy-options="{
            placement: 'auto-end',
            interactive: true,
            offset: [10,20]
          }"
        >
          <DynamicDataInput
            :query-term="queryString"
            @input="insertDynamicData"
          />
        </bubble-menu>

        <div>
          <editor-content class="editor__content"
                          :editor="editor"
                          @drop="false"
          />
        </div>
        <div class="menubar"
             :class="(editorInFocus && (editable || editor.isActive())) || isEmojiPickerDisplayed ? 'is-focused' : 'is-hidden'"
        >
          <b-button-toolbar class="rich-text-toolbar"
                            aria-label="Toolbar with button groups"
          >

            <b-button :pressed="editor.isActive('bold')"
                      @click="editor.chain().focus().toggleBold().run()"
                      class="font-size-sm"
            >
              <icon name="bold"/>
            </b-button>

            <b-button :pressed="editor.isActive('italic')"
                      @click="editor.chain().focus().toggleItalic().run()"
                      class="font-size-sm"
            >
              <icon name="italic"/>
            </b-button>

            <b-button :pressed="editor.isActive('strike')"
                      @click="editor.chain().focus().toggleStrike().run()"
                      class="font-size-sm"
            >
              <icon name="strikethrough"/>
            </b-button>

            <b-button :pressed="editor.isActive('underline')"
                      @click="editor.chain().focus().toggleUnderline().run()"
                      class="font-size-sm"
            >
              <icon name="underline"/>
            </b-button>

            <b-button :pressed="editor.isActive('code')"
                      @click="editor.chain().focus().toggleCode().run()"
                      class="font-size-sm"
            >
              <icon name="code"/>
            </b-button>

            <b-modal :title="staticText.modalTitle"
                     v-model="linkMenuIsActive"
                     size="sm"
                     :ok-title="staticText.modalOkTitle"
                     @ok.prevent="setLinkUrl(editor.commands.setLink, linkUrl)"
            >
              <b-form-input type="url"
                            v-model="linkUrl"
              />
            </b-modal>

            <b-button class="menububble__button font-size-sm"
                      @click="showLinkMenu()"
            >
              <icon name="link"/>
            </b-button>

            <b-button :pressed="editor.isActive('paragraph')"
                      @click="editor.chain().focus().setParagraph().run()"
                      class="font-size-sm"
            >
              <icon name="paragraph"/>
            </b-button>

            <b-button :pressed="editor.isActive('heading', { level: 1 })"
                      @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
                      class="font-size-sm"
            >
              H1
            </b-button>

            <b-button :pressed="editor.isActive('heading', { level: 2 })"
                      @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
                      class="font-size-sm"
            >
              H2
            </b-button>

            <b-button :pressed="editor.isActive('heading', { level: 3 })"
                      @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
                      class="font-size-sm"
            >
              H3
            </b-button>

            <b-button :pressed="editor.isActive('heading', { level: 4 })"
                      @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
                      class="font-size-sm"
            >
              H4
            </b-button>

            <b-button :pressed="editor.isActive('heading', { level: 5 })"
                      @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
                      class="font-size-sm"
            >
              H5
            </b-button>

            <b-button :pressed="editor.isActive('heading', { level: 6 })"
                      @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
                      class="font-size-sm"
            >
              H6
            </b-button>

            <b-button :pressed="editor.isActive('bulletList')"
                      @click="editor.chain().focus().toggleBulletList().run()"
                      class="font-size-sm"
            >
              <icon name="list-ul"/>
            </b-button>

            <b-button :pressed="editor.isActive('orderedList')"
                      @click="editor.chain().focus().toggleOrderedList().run()"
                      class="font-size-sm"
            >
              <icon name="list-ol"/>
            </b-button>

            <b-button @click="editor.chain().focus().undo().run()"
                      class="font-size-sm"
            >
              <icon name="undo"/>
            </b-button>

            <b-button @click="editor.chain().focus().redo().run()"
                      class="font-size-sm"
            >
              <icon name="redo"/>
            </b-button>

            <rich-text-emoji-picker
              ref="richTextEmojiPicker"
              @insert-emoji="insertEmoji"
              @emoji-display-value="emojiDisplayUpdate"
            />

            <div v-if="dynamicData"
                 type="button"
                 :class="['ml-1', 'dynamic-data-btn', 'text-slate-80', {'dynamic-data-btn__selected': dynamicDataActive}]"
                 @click="toggleDynamicDataInputBtn"
            >
              <span :class="['font-weight-bold', 'mr-1']"
              >
                { }
              </span>
              <span>{{ staticText.dynamicDataLabel }}</span>
            </div>
          </b-button-toolbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RichTextEmojiPicker from "./RichTextEmojiPicker.vue"
import Icon from "./RichTextEditorIcon"
import { BubbleMenu, Editor, EditorContent } from "@tiptap/vue-2"
import Document from "@tiptap/extension-document"
import Paragraph from "@tiptap/extension-paragraph"
import Text from "@tiptap/extension-text"
import Blockquote from "@tiptap/extension-blockquote"
import Bold from "@tiptap/extension-bold"
import BulletList from "@tiptap/extension-bullet-list"
import Code from "@tiptap/extension-code"
import CodeBlock from "@tiptap/extension-code-block"
import HardBreak from "@tiptap/extension-hard-break"
import Heading from "@tiptap/extension-heading"
import History from "@tiptap/extension-history"
import HorizontalRule from "@tiptap/extension-horizontal-rule"
import Italic from "@tiptap/extension-italic"
import ListItem from "@tiptap/extension-list-item"
import OrderedList from "@tiptap/extension-ordered-list"
import Strike from "@tiptap/extension-strike"
import Link from "@tiptap/extension-link"
import Underline from "@tiptap/extension-underline"
import Placeholder from "@tiptap/extension-placeholder"
import TaskItem from "@tiptap/extension-task-item"
import TaskList from "@tiptap/extension-task-list"

import DynamicDataInput from "@/apps/talkscript/components/DynamicDataInput"
import { CustomHighlight } from "@/apps/richtexteditor/customHighlight"
import { CustomMention } from "@/apps/richtexteditor/customMention"

export default {
  name: "RichTextEditor",
  components: {
    EditorContent,
    Icon,
    RichTextEmojiPicker,
    BubbleMenu,
    DynamicDataInput
  },
  props: {
    dynamicData: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ""
    },
    editable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasInnerLabel: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      staticTextDefault: {
        modalOkTitle: "Save",
        modalTitle: "Add link",
        dynamicDataLabel: "Dynamic Data"
      },
      keepInBounds: true,
      editor: null,
      editorInFocus: false,
      linkUrl: null,
      linkMenuIsActive: false,
      isEmojiPickerDisplayed: false,
      dynamicDataActive: false,
      filterProps: null,
      queryString: ""
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    getEditorClass () {
      const editorClass = ["editor-container-202402291745"]
      if (this.editable) editorClass.push(...["form-control rich-text-area"])
      if (this.hasInnerLabel) editorClass.push("inner-label")
      if (this.disabled) editorClass.push("disabled")
      return editorClass
    }
  },
  watch: {
    value (val) {
      if (this.editor.getHTML() !== val) {
        this.editor.commands.setContent(val)
      }
    },
    editable (val) {
      // if the watch is not added, then the editor does not change its state accordingly
      this.editor.options.editable = val
    }
  },
  mounted () {
    const editorExtensions = [
      Document,
      Paragraph,
      Text,
      Blockquote,
      BulletList,
      CodeBlock,
      HardBreak,
      Heading,
      HorizontalRule,
      ListItem,
      OrderedList,
      TaskItem,
      TaskList,
      Link,
      Bold,
      Code,
      Italic,
      Strike,
      Underline,
      History,
      Placeholder.configure({
        emptyNodeClass: "is-empty",
        placeholder: this.placeholder,
        showOnlyWhenEditable: false
      })
    ]

    // dynamic data specific extensions
    if (this.dynamicData) {
      editorExtensions.push(
        CustomHighlight.configure({ multicolor: true }),
        CustomMention.configure({
          suggestion: {
            items: ({ query }) => {
              this.queryString = query
            },
            render: () => {
              const component = this
              return {
                onStart: (props) => {
                  component.filterProps = props
                  if (props.text === "{{") {
                    component.dynamicDataActive = !!props.text
                    component.queryString = props.text
                  }
                },
                onUpdate (props) {
                  component.filterProps = props
                },
                onKeyDown (props) {
                  if (props.event.key === "Escape") {
                    component.dynamicDataActive = false
                    return true
                  }
                  return false
                },
                onExit () {
                  component.dynamicDataActive = false
                }
              }
            }
          }
        })
      )
    }

    this.editor = new Editor({
      extensions: editorExtensions,
      content: "This is a test",

      onUpdate: ({ editor }) => {
        let htmlContent = editor.getHTML()
        // remove empty paragraph tags when text is deleted.
        if (htmlContent === "<p></p>") htmlContent = ""
        this.$emit("input", htmlContent)
      },
      editable: this.editable,
      editorProps: {
        handleDOMEvents: {
          drop: (view, e) => {
            e.preventDefault()
          }
        }
      },
      onFocus: () => {
        if (this.editable) {
          this.editorInFocus = true
          this.$refs.richTextEmojiPicker.checkIfEmojiDisplay()
        }
      },
      onBlur: () => {
        this.editorInFocus = false
        this.$emit("blur")
      }
    })
    this.editor.commands.setContent(this.value, false, { preserveWhitespace: "full" })
    document.addEventListener("click", this.handleClickOutside, true)
  },
  beforeDestroy () {
    this.editor.destroy()
    document.removeEventListener("click", this.handleClickOutside, true)
  },
  methods: {
    toggleDynamicDataInputBtn () {
      if (!this.dynamicDataActive) {
        this.dynamicDataActive = true
        this.editor.commands.insertContent("{{")
      } else {
        this.dynamicDataActive = false
      }
      // this.queryString = ""
      this.editor.chain().focus().run()
    },
    insertDynamicData (payload) {
      const { filtered, value, htmlContent } = payload
      this.dynamicDataActive = false
      this.queryString = ""
      this.editor.chain().focus().run()

      filtered
        ? this.filterProps.command({
          id: value.id,
          label: value.label
        })
        : this.editor.commands.insertContent(htmlContent)
    },
    showLinkMenu () {
      const selectedText = window.getSelection().toString()
      if (selectedText) {
        this.linkUrl = "https://www." + selectedText
        this.linkMenuIsActive = true
      }
    },
    hideLinkMenu () {
      this.linkUrl = null
      this.linkMenuIsActive = false
    },
    setLinkUrl (command, url) {
      command({ href: url, target: "_blank" })
      this.hideLinkMenu()
    },
    insertEmoji (emoji) {
      this.editor.commands.insertContent(emoji)
      this.editor.chain().focus().run()
    },
    emojiDisplayUpdate (value) {
      this.isEmojiPickerDisplayed = value
    },
    handleClickOutside (event) {
      const editorElement = this.$el.querySelector(".editor")
      // Check if the click was outside the editor
      if (!editorElement.contains(event.target)) {
        this.dynamicDataActive = false
      }
    }
  }
}
</script>

<style lang="scss">

#rich-text-editor-202408150831 {

  .inactive-playbook-item .rich-text-area {
    background: none;
  }

  .is-hidden {
    visibility: hidden;
    height: 0;
    opacity: 0;
  }

  .is-focused {
    visibility: visible;
    height: 100%;
    opacity: 1;
    margin-top: 5px;
    padding: 2px 0;
    margin-bottom: 2px;
  }

  .menubar {
    background-color: $white;
    transition-property: visibility, opacity, height, margin, padding;
    transition-duration: 0.3s;
    transition-delay: 0.1s;
    display: block;
    border-top: 1px solid darkgray;
  }

  p.is-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: $slate60;
    pointer-events: none;
    height: 0;
  }

  .rich-text-toolbar {
    font-size: small;
    .btn-secondary {
      padding: 4px 9px !important;
      background-color: $white !important;
      border-radius: 6px !important;
      &:hover {
        background-color: $slate12 !important;
      }
      &:not(:disabled):not(.disabled).active {
        color: $white !important;
        background-color: $slate80 !important;
      }
    }
  }

  .rich-text-area {
    height: 100%;
    padding: 10px;
  }

  .ProseMirror {
    outline: none !important;
    p {
      margin: 0;
      padding: 0;
    }
  }

  .is-empty {
    margin-bottom: 0px !important;
  }

  .font-size-sm {
    font-size: small;
  }

  .dynamic-data-btn {
    display: flex;
    align-items: center;
    font-size: 12px;
    border-radius: 4px;
    padding: 6px;
    white-space: nowrap;

    &:hover, &__selected {
      background: $slate10;
    }
  }

  .editor-container-202402291745 {
    position: relative;

    &.inner-label {
      padding: 20px 16px 8px;
    }

    &.disabled {
      background-color: rgba(255, 255, 255, 0.4);
      color: rgba(127, 129, 151, 1);
      border: none;
      pointer-events: none;
    }
  }

  // These classes here were set to revert the interference of the calltime extension
  // If the way styles are handled in the extension is improved, this would not be
  // required anymore
  [data-tippy-root] {
    max-width: unset;
  }
  .tippy-box {
    background-color: unset;
    border-radius: unset;
    color: unset;
    font-size: unset;
    line-height: unset;
    white-space: unset;
    .tippy-content {
      padding: unset;
      z-index: unset;
    }
    .tippy-arrow {
      display: none;
    }
  }
}
</style>
