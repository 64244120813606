<template>
  <div>
    <i :class="'fas ' + 'fa-' + name + ' ' + size + ' ' + modifier"></i>
  </div>
</template>

<script>
export default {
  name: "RichTextEditorIcon",
  props: {
    name: {},
    size: {
      default: "fa-xs"
    },
    modifier: {
      default: null
    },
    fixAlign: {
      default: true
    }
  }
}
</script>

<style>
</style>
