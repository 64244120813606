<template>
  <b-card body-class="px-2 py-1"
          class="bubble-menu__card"
  >
    <b-list-group v-for="(group, index) in dynamicDataGroups"
                  :key="index"
                  :class="{'inactive': !group.open}"
                  class="bubble-menu__card__group pb-2"
    >
      <div class="bubble-menu__card__group__title pt-2"
           type="button"
           @click="toggleGroupDetail(group)"
      >
        {{ translate(group.title) }}
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" :class="['toggle-dropdown-icon', { 'show' : group.open }]" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4.002 7.851a.847.847 0 01.456-.753.86.86 0 01.946.099l4.584 3.808 4.593-3.672a.857.857 0 011.206.127.848.848 0 01-.128 1.241l-5.132 4.106a.858.858 0 01-1.086 0l-5.132-4.25a.85.85 0 01-.307-.706z" fill="#2A2D52"/></svg>
      </div>
      <b-collapse :visible="group.open">
        <!-- User's Data-->
        <div v-if="group.type===GROUP_TYPE.USER_DATA">
          <b-list-group class="bubble-menu__card__sub-group scrollable-content shadow-sm p-2 pt-1">
            <div v-if="getFilterSet(group.items, queryTerm).length">
              <b-list-group-item
                v-for="(item, itemIndex) in getFilterSet(group.items, queryTerm)"
                :key="itemIndex"
                button
                class="bubble-menu__card__sub-group__fields no-border p-0 m-0 mt-1"
                @click="selectUserData(item)"
              >
                <span class="d-flex border-bottom w-100 p-1">
                  {{ translateAddPlaceholder(item) }}
                </span>
              </b-list-group-item>
            </div>
            <div v-else>{{ staticText.noResultsMessage }}</div>
          </b-list-group>
        </div>

        <!-- CRM Data-->
        <div v-if="group.type===GROUP_TYPE.CRM_DATA && !!group.crmItems">

          <!-- CRM Objects-->
          <BaoVueMultiSelect
            class="shadow-sm rounded-lg mt-1"
            :options="group.crmItems"
            :show-labels="false"
            :allowEmpty="false"
            v-model="selectedCrmObject"
            label="label"
            :placeholder="staticText.selectCrmObjectPlaceholder"
            track-by="id"
            @input="toggleCrmObjectDetail(selectedCrmObject)"
          ></BaoVueMultiSelect>

          <!-- fields for CRM Objects-->
          <div v-if="selectedCrmObject"
               class="mt-1"
          >
            <div v-if="fieldsLoading && !selectedCrmObject.fieldDefinitions">
              <progress-indicator :show-loading-label="false"
                                  :small="true"
                                  class="p-2"
              ></progress-indicator>
            </div>
            <div v-else>
              <b-list-group class="bubble-menu__card__sub-group scrollable-content shadow-sm p-2 pt-1">
                <div v-if="getFilterSet(selectedCrmObject.fieldDefinitions, queryTerm).length">
                  <b-list-group-item
                    v-for="definition in getFilterSet(selectedCrmObject.fieldDefinitions, queryTerm)"
                    :key="definition.id"
                    :disabled="!definition.supported"
                    button
                    class="bubble-menu__card__sub-group__fields no-border p-0 m-0 mt-1"
                    @click.stop="selectCRMData(selectedCrmObject, definition)"
                  >
                    <span class="d-flex border-bottom w-100 p-1">
                      {{ insertIntoPlaceholder(definition.label) }}
                    </span>
                  </b-list-group-item>
                </div>
                <div v-else>{{ staticText.noResultsMessage }}</div>
              </b-list-group>
            </div>
          </div>
        </div>

        <!-- Other Items Data-->
        <div v-if="group.type===GROUP_TYPE.OTHER_ITEM_DATA">
          <b-form-group class="bubble-menu__card__radio-group">
            <b-form-radio v-for="(item, itemIndex) in group.items"
                          :key="itemIndex"
                          v-model="selectedRadioGroup"
                          :value="item"
            >
              {{ translate(item) }}
            </b-form-radio>
          </b-form-group>
          <div v-if="!!selectedRadioGroup">

            <!--   Select Answer from Item         -->
            <div v-if="selectedRadioGroup.en===OTHER_ITEM_DATA_TYPE.ANSWER">
              <BaoVueMultiSelect
                class="shadow-sm rounded-lg"
                :options="playbookItemsWithAnswers"
                :show-labels="false"
                :allowEmpty="false"
                v-model="selectedPlaybookItem"
                label="name"
                :placeholder="staticText.selectItemPlaceholder"
                track-by="id"
                @input="handleAnswerFromItemSelected(selectedPlaybookItem)"
              ></BaoVueMultiSelect>

              <!--   Answer choices to select  from selected Playbook item  -->
              <div v-if="answersToSelect.length" class="mt-1">
                <b-list-group class="bubble-menu__card__sub-group scrollable-content shadow-sm p-2 pt-1">
                  <div v-if="getFilterSet(answersToSelect, queryTerm).length">
                    <b-list-group-item
                      v-for="answer in getFilterSet(answersToSelect, queryTerm)"
                      :key="answer.id"
                      button
                      class="bubble-menu__card__sub-group__fields no-border p-0 m-0 mt-1"
                      @click.stop="selectAnswerFromItemData(selectedPlaybookItem, answer)"
                    >
                      <span class="d-flex border-bottom w-100 p-1">
                        {{ insertIntoPlaceholder(answer.label) }}
                      </span>
                    </b-list-group-item>
                  </div>
                  <div v-else>{{ staticText.noResultsMessage }}</div>
                </b-list-group>
              </div>
            </div>

            <!--   Select Note from Item         -->
            <div v-if="selectedRadioGroup.en===OTHER_ITEM_DATA_TYPE.NOTE">
              <BaoVueMultiSelect
                class="shadow-sm rounded-lg"
                :options="playbookItems"
                :show-labels="false"
                :allowEmpty="false"
                :value="selectedPlaybookItem"
                label="name"
                :placeholder="staticText.selectItemPlaceholder"
                track-by="id"
                @input="selectNoteFromItemData"
              ></BaoVueMultiSelect>
            </div>
          </div>

        </div>
      </b-collapse>
    </b-list-group>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import {
  ANSWER_FROM_KEY,
  ANSWER_KEY,
  COUNTERPART_FIRST_NAME_KEY,
  COUNTERPART_LAST_NAME_KEY,
  COUNTERPART_NAME_KEY,
  CRM_DATA_TITLE,
  CRM_NAME_KEY,
  CURRENT_DATE_KEY,
  CURRENT_TIME_KEY,
  FIRST_NAME_KEY,
  GROUP_TYPE,
  LAST_NAME_KEY,
  NOTE_FROM_KEY,
  NOTE_KEY,
  OTHER_ITEM_DATA_TYPE,
  OTHER_ITEMS_DATA_TITLE,
  USER_DATA_TITLE
} from "@/apps/talkscript/components/dynamicDataIndex"
import ProgressIndicator from "@/apps/base/ProgressIndicator"
import BaoVueMultiSelect from "@/apps/base/BaoVueMultiSelect"
import colors from "@/assets/scss/main.scss"

export default {
  name: "DynamicDataInput",
  components: {
    ProgressIndicator,
    BaoVueMultiSelect
  },
  props: {
    queryTerm: {
      type: String,
      required: false,
      default: ""
    }
  },
  data () {
    return {
      staticTextDefault: {
        selectCrmObjectPlaceholder: "Select CRM Object",
        selectItemPlaceholder: "Select an Item",
        noResultsMessage: "No Results. Try a Different Query."
      },
      GROUP_TYPE,
      OTHER_ITEM_DATA_TYPE,
      selectedCrmObject: null,
      selectedCrmObjects: [],
      selectedRadioGroup: null,
      selectedPlaybookItem: null,
      answersToSelect: [],
      userData: {
        type: GROUP_TYPE.USER_DATA,
        title: USER_DATA_TITLE,
        items: [
          FIRST_NAME_KEY, LAST_NAME_KEY, CURRENT_DATE_KEY, CURRENT_TIME_KEY, COUNTERPART_NAME_KEY,
          COUNTERPART_FIRST_NAME_KEY, COUNTERPART_LAST_NAME_KEY, CRM_NAME_KEY
        ]
      },
      crmData: {
        type: GROUP_TYPE.CRM_DATA,
        title: CRM_DATA_TITLE,
        crmItems: null,
        items: []
      },
      otherItemsData: {
        type: GROUP_TYPE.OTHER_ITEM_DATA,
        title: OTHER_ITEMS_DATA_TITLE,
        items: [
          ANSWER_KEY, NOTE_KEY
        ]
      }
    }
  },
  mounted () {
    this.setUpInitialData()
  },
  watch: {
    fieldsLoading (val) {
      if (!val) {
        this.selectedCrmObjects.forEach(crmObject => {
          this.$set(crmObject, "fieldDefinitions", this.fieldDefinitions[crmObject.id])
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      canUseCRM: "auth/canUseCRM",
      canStoreCRMData: "auth/canStoreCRMData",
      objectDefinitions: "crmStore/getObjectDefinitions",
      fieldDefinitions: "crmStore/getFieldDefinitions",
      loadingFieldDefinitions: "crmStore/getLoadingFieldDefinitions",
      playbookItems: "playbookConfiguratorStore/getPlaybookItems"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    },
    playbookItemsWithAnswers () {
      return this.playbookItems.filter(item => {
        return item.answer_choices.length || item.linked_field.id
      })
    },
    showOtherItemsData () {
      return this.playbookItems.length > 1
    },
    dynamicDataGroups () {
      const dynamicDataBaseGroups = [this.userData]
      if (this.canUseCrmData) dynamicDataBaseGroups.push(this.crmData)
      if (this.showOtherItemsData) dynamicDataBaseGroups.push(this.otherItemsData)
      return dynamicDataBaseGroups
    },
    canUseCrmData () {
      return this.canStoreCRMData && this.canUseCRM
    },
    fieldsLoading () {
      return this.loadingFieldDefinitions.length > 0
    }
  },
  methods: {
    ...mapActions({
      retrieveFieldDefinitions: "crmStore/retrieveFieldDefinitions"
    }),
    resetSelectedPlaybookItem () {
      this.selectedPlaybookItem = null
    },
    getFilterSet (items, queryString) {
      return queryString.length >= 1 && queryString !== "{{"
        ? items.filter(item => {
          const startsWithQuery = (text) => {
            return text ? text.toLowerCase().indexOf(queryString.toLowerCase()) > -1 : false
          }
          return startsWithQuery(item.en) || startsWithQuery(item.de) || startsWithQuery(item.label)
        })
        : items
    },
    handleAnswerFromItemSelected (item) {
      if (item.linked_field && item.linked_field.field_definition && item.linked_field.field_definition.length) {
        this.answersToSelect = item.linked_field.field_definition
        return
      }
      this.answersToSelect = []
      this.resetSelectedPlaybookItem()
      const label = `${this.translate(ANSWER_FROM_KEY)} ${item.name}`
      const identifier = `${OTHER_ITEM_DATA_TYPE.ANSWER},${label},${item.id}`
      this.selectDynamicData({ id: identifier, label })
    },
    selectUserData (item) {
      const label = this.translate(item)
      const identifier = `${GROUP_TYPE.USER_DATA},${label},${null}`
      this.selectDynamicData({ id: identifier, label })
    },
    selectCRMData (crmObject, definition) {
      const { id, label } = this.getFieldValueToSelect(crmObject, definition)
      const identifier = `${GROUP_TYPE.CRM_DATA},${label},${id}`
      this.selectDynamicData({ id: identifier, label })
    },
    selectNoteFromItemData (item) {
      const label = `${this.translate(NOTE_FROM_KEY)} ${item.name}`
      const identifier = `${OTHER_ITEM_DATA_TYPE.NOTE},${label},${item.id}`
      this.selectDynamicData({ id: identifier, label })
    },
    selectAnswerFromItemData (item, answer) {
      // As of now answer is a field of selected CRM Object.
      // In the future, in case we want to select other answers inside playbook item, update accordingly.
      const label = `${answer.label}-${this.translate(ANSWER_FROM_KEY)} ${item.name}`
      const identifier = `${OTHER_ITEM_DATA_TYPE.ANSWER},${label},${item.id},${answer.id}`
      this.selectDynamicData({ id: identifier, label })
      this.resetSelectedPlaybookItem()
      this.answersToSelect = []
    },
    selectDynamicData (val) {
      // data-id attribute can be used to identify dynamic data by their id, instead of label
      // specially because labels can be duplicated but ids should be unique.
      // possible use cases: crm_id of CRM fields, question_id from answer choices, playbookItem id etc.
      // expected value.id format: comma separated string as follows: dataGroupType,dataLabel,dataId,additionalDataId
      const dataId = `{{${val.id}}}`
      const label = val.label
      const content = `<span data-color="${colors.orange12}" data-id="${dataId}">{{${label}}}</span> `
      this.$emit("input", { filtered: this.queryTerm.length > 0, value: { id: dataId, label }, htmlContent: content })
    },
    crmServiceFieldKeyMapping (crmService) {
      const _mapping = {
        dynamics365: "logical_name",
        salesforce: "id",
        hubspot: "id",
        pipedrive: "description",
        salesloft: "label"
      }
      return _mapping[crmService]
    },
    getFieldValueToSelect (crmObject, fieldDefinition) {
      const crmService = crmObject.service_key
      const fieldKey = this.crmServiceFieldKeyMapping(crmService)
      let idValue = `${crmObject.object_type}-${fieldDefinition[fieldKey]}`

      // if selected CRM field is of lookup type, we add additional information about the field in data-id attribute
      // so that we can retrieve value for the field in conversation view directly from CRM.
      // Note: currently lookup fields are supported only for dynamics365.
      if (fieldDefinition.fieldType === "lookup" && crmService === "dynamics365") {
        const fieldId = `${crmObject.object_type}-_${fieldDefinition[fieldKey]}_value`
        const lookupKey = `lookup-${fieldDefinition.lookup_targets[0]}` // lookup-lookupTarget
        idValue = `${fieldId},${lookupKey}`
      }

      return {
        id: idValue,
        label: `${crmObject.object_type}-${fieldDefinition.label}`
      }
    },
    toggleGroupDetail (group) {
      this.$set(group, "open", !group.open)
      this.dynamicDataGroups.forEach(item => {
        if (item.type !== group.type && !!item.open) {
          this.$set(item, "open", false)
        }
      })
      if (group.type === GROUP_TYPE.CRM_DATA && !group.crmItems) {
        // attach all available CRM Objects as crmItems into the crm data group.
        this.$set(group, "crmItems", this.objectDefinitions)
      }
    },
    toggleCrmObjectDetail (crmObject) {
      if (this.selectedCrmObjects.indexOf(crmObject) === -1) this.selectedCrmObjects.push(crmObject)
      this.$set(crmObject, "open", !crmObject.open)
      const fieldsForObject = this.fieldDefinitions[crmObject.id]
      if (fieldsForObject) {
        this.$set(crmObject, "fieldDefinitions", fieldsForObject)
      } else this.retrieveFieldDefinitions([crmObject])
    },
    setUpInitialData () {
      this.dynamicDataGroups.forEach(group => {
        if (group.type === GROUP_TYPE.USER_DATA) {
          this.$set(group, "open", true)
        } else this.$set(group, "open", false)
      })
    },
    insertIntoPlaceholder (text) {
      return `{{${text}}}`
    },
    translate (textObj) {
      return textObj[this.user.language] ? textObj[this.user.language] : textObj.label
    },
    translateAddPlaceholder (multiLangText) {
      return this.insertIntoPlaceholder(this.translate(multiLangText))
    }
  }
}
</script>

<style lang="scss" scoped>

.scrollable-content {
  max-height: 150px;
  overflow-y: auto;
}

.bubble-menu__card {
  border-radius: 12px;

  &__radio-group {
    padding: 5px;
    margin-bottom: 0;
  }

  &__sub-group {
    &__fields {
      &:last-child {
        span {
          border: none !important;
        }
      }
    }
  }

  &__group {
    background: $white80;

    &.inactive {
      opacity: 0.6;
    }

    &:first-child {
      .bubble-menu__card__group__title {
        border: none;
      }
    }

    &__title {
      min-width: 200px;
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      font-size: 15px;
      border-top: 1px solid rgba(42, 45, 82, 0.5);
    }

    :deep(.multiselect__tags) {
      min-height: 40px !important;
      padding: 9px 35px 0 0 !important;
    }

    :deep(.multiselect__select) {
      padding: unset !important;
    }

    :deep(.multiselect__single) {
      margin-top: unset !important;
    }

    :deep(.multiselect__input) {
      max-width: 160px !important;
    }

    :deep(.multiselect__input:focus) {
      margin-top: unset !important;
    }

    .toggle-dropdown-icon {
      transition: 0.3s ease-in;

      &.show {
        transform: rotate(180deg);
      }
    }
  }

}

</style>
